<template>
  <div class="qrcode-print-container">
    <shelf-list :shelf-info="shelfDetail" ref="listRef">
      <template #default="{data,index}">
        <div class="shelf-child-list-content-item">
          <img :src="arr[index].value" alt="" class="shelf-child-list-content-item-img"/>
          <div class="shelf-child-list-content-item-title">{{ data.childShelfName }}</div>
        </div>
      </template>
    </shelf-list>
  </div>
</template>

<script setup>
import {computed, ref, watchEffect} from "vue";
import {useQRCode} from '@vueuse/integrations/useQRCode';
import shelfList from "@views/personal/shelfManagement/component/shelfList/index.vue";
import {useStorage} from '@vueuse/core'

let first = false
const listRef = ref(null)
const shelfDetail = ref(JSON.parse(useStorage('shelf-child').value || '{}'))
const arr = computed(()=>{
  if (shelfDetail.value?.row && shelfDetail.value?.column) {
    return shelfDetail.value.childList.map(item => {
      const url = window.location.origin + '/home'
      const text = encodeURI(`?childShelfId=${item.childShelfId}&shelfId=${shelfDetail.value.shelfId}`)
      return useQRCode(url + text)
    })
  }
})

watchEffect(() => {
  if (listRef.value?.frameCount >= shelfDetail.value?.column && !first) {
    first = true
    requestAnimationFrame(() => {
      print()
    })
  }
})
</script>
<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: "QRcodeModal",
});
</script>

<style lang="scss" scoped>
@import '../../../common';

.qrcode-print-container {
  width: 260mm;
}

</style>
